import React from "react";
import * as S from "./styles";
import { LayoutTemplate } from "../LayoutTemplate";
import { Link, useNavigate } from "react-router-dom";
import { Button } from "../../Atoms/Button";
import { useMediaQuery } from "react-responsive";
import CookieBannerPage from "../../Atoms/CookieBanner";

export const HomeTemplate = () => {
  const isMobile = useMediaQuery({ maxWidth: "500px" });
  const navigate = useNavigate();

  return (
    <LayoutTemplate>
      <CookieBannerPage />
      <S.Container>
        <S.SectioStarCheck>
          <S.SectioStarCheckLeftSide>
            <S.SectioStarCheckLeftSideContent>
              <h1>
                Star{" "}
                <span>
                  Check{" "}
                  <img
                    src="assets/imgs/check-icon.svg"
                    alt="icone de checado"
                  />
                </span>
              </h1>

              <p>
                Sua empresa de Vistoria Veicular, especializada em análises de
                veículos, com foco em segurança e transparência.
              </p>

              <Button
                className="buttonAgendamento"
                data-variant-degrade
                onClick={() => navigate("agendamento")}
              >
                AGENDAR VISTORIA
              </Button>
            </S.SectioStarCheckLeftSideContent>
          </S.SectioStarCheckLeftSide>

          <S.SectioStarCheckRightSide>
            <S.SectioStarCheckRightSideContent>
              <img
                src={
                  isMobile
                    ? "assets/imgs/starcheck-mobile.png"
                    : "assets/imgs/starcheck.png"
                }
                alt="imagem mecanico"
              />
            </S.SectioStarCheckRightSideContent>
          </S.SectioStarCheckRightSide>
        </S.SectioStarCheck>

        <S.SectionAbout id="sobre">
          <S.SectionAboutContent>
            <S.Title>
              Sobre
              <span>
                Nós <img src={"assets/imgs/check-icon.svg"} alt="icone check" />
              </span>
            </S.Title>
            <S.TextDefault>
              A <S.TextStrong>StarCheck</S.TextStrong> destaca-se pela sua{" "}
              <S.TextStrong>precisão</S.TextStrong>,{" "}
              <S.TextStrong>compromisso</S.TextStrong> com a{" "}
              <S.TextStrong>qualidade</S.TextStrong> e{" "}
              <S.TextStrong>atendimento excepcional</S.TextStrong> ao cliente.
              Valorizamos sua satisfação e nos esforçamos para superar suas
              expectativas. Nossa equipe está pronta para fornecer orientações
              especializadas e garantir que suas necessidades sejam atendidas de
              maneira eficiente.
            </S.TextDefault>
            <S.TextDefault>
              Seja de forma domiciliar ou em nossos pontos fixos, a{" "}
              <S.TextStrong>StarCheck </S.TextStrong>
              está pronta para ser sua parceira em todas as suas necessidades de
              vistorias veicular. Conte conosco para promover a segurança e
              proporcionar tranquilidade.
            </S.TextDefault>

            <Link to={"agendamento"}>
              <Button data-variant-degrade>AGENDAR VISTORIA</Button>
            </Link>

            <S.SectionCards>
              <S.WrapperCard>
                <S.SubTitle>Missão</S.SubTitle>
                <S.Card>
                  <p>
                    Contribuir para a segurança no mercado de{" "}
                    <S.TextBlue>veículos</S.TextBlue>, realizando vistorias
                    confiáveis ​​e precisas que garantem a tranquilidade para
                    você.
                  </p>
                </S.Card>
              </S.WrapperCard>

              <S.WrapperCard>
                <S.SubTitle>Visão</S.SubTitle>
                <S.Card>
                  <p>
                    Nosso propósito é ser referência em{" "}
                    <S.TextBlue>Vistoria Veicular</S.TextBlue>, destacando-nos
                    por excelência no serviço prestado.
                  </p>
                </S.Card>
              </S.WrapperCard>

              <S.WrapperCard>
                <S.SubTitle>Valores</S.SubTitle>
                <S.Card>
                  <S.Grid $gridTemplate=".2fr 1fr" $gap="16px">
                    <img
                      src="assets/imgs/check-icon.svg"
                      alt="icone de checado"
                    />
                    <h4>HONESTIDADE</h4>

                    <img
                      src="assets/imgs/check-icon.svg"
                      alt="icone de checado"
                    />
                    <h4>COMPROMETIMENTO</h4>

                    <img
                      src="assets/imgs/check-icon.svg"
                      alt="icone de checado"
                    />
                    <h4>RESPEITO</h4>

                    <img
                      src="assets/imgs/check-icon.svg"
                      alt="icone de checado"
                    />
                    <h4>CONFIANÇA</h4>
                  </S.Grid>
                </S.Card>
              </S.WrapperCard>
            </S.SectionCards>
          </S.SectionAboutContent>
        </S.SectionAbout>

        <S.SectionServices id="servicos">
          <S.SectionServicesContent>
            <S.Title>
              Serviços{}{" "}
              <img src="assets/imgs/check-icon.svg" alt="icone de checado" />
            </S.Title>
            <S.TextDefault>
              <S.TextStrong>Compre</S.TextStrong> e{" "}
              <S.TextStrong>agende online</S.TextStrong> sua vistoria conosco e
              tenha certeza da realização do serviço.
            </S.TextDefault>

            <S.TableService>
              <thead>
                <tr>
                  <td colSpan={2}>
                    <S.TableWrapperTitle>
                      <img
                        src="assets/imgs/pin-icon.svg"
                        alt="pin de localização"
                      />
                      <S.TitleTable>
                        Vistoria de Transferência / Primeiro Emplacamento{" "}
                        <S.TextBlue>Posto fixo</S.TextBlue>
                      </S.TitleTable>
                    </S.TableWrapperTitle>
                  </td>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>
                    <p>Carro e Moto</p>
                  </td>
                  <td>
                    {" "}
                    <h3>
                      R$ 152
                      {/* <span>**</span> */}
                    </h3>
                  </td>
                </tr>

                <tr>
                  <td>
                    <p>
                      Veículos Grande Porte
                      <S.TextRed> *</S.TextRed>
                    </p>
                  </td>
                  <td>
                    {" "}
                    <h3>
                      R$ 190
                      {/* <span>**</span> */}
                    </h3>
                  </td>
                </tr>
                <tr>
                  <td colSpan={2}>
                    <S.TableWrapperTitle>
                      <img
                        src="assets/imgs/mapa-icon.svg"
                        alt="pin de localização"
                      />
                      <S.TitleTable>
                        Vistoria de Transferência / Primeiro Emplacamento{" "}
                        <S.TextBlue>Domicilar</S.TextBlue>
                      </S.TitleTable>
                    </S.TableWrapperTitle>
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colSpan={2}>
                    <h3>
                      R$ 190
                      {/* <span>**</span> */}
                    </h3>
                  </td>
                </tr>
              </tfoot>
            </S.TableService>

            <S.Textplus>
              <S.TextRed>*</S.TextRed>Ônibus <S.TextBlue>-</S.TextBlue>{" "}
              Micro-ônibus <S.TextBlue>-</S.TextBlue> Reboque{" "}
              <S.TextBlue>-</S.TextBlue> Semirreboque <S.TextBlue>-</S.TextBlue>{" "}
              Caminhão <S.TextBlue>-</S.TextBlue> Caminhão Trator{" "}
              <S.TextBlue>-</S.TextBlue> Trator de Rodas{" "}
              <S.TextBlue>-</S.TextBlue> Trator Misto <S.TextBlue>-</S.TextBlue>{" "}
              Chassi/Plataforma
            </S.Textplus>
            {/* <S.Textplus>
              <S.TextRed>*</S.TextRed>
              <S.TextRed>*</S.TextRed>Taxa será acrescida no valor da compra.
              Taxa bancária no{" "}
              <S.TextStrong>Boleto</S.TextStrong> no valor de{" "}
              <S.TextBlue>R$ 3,92</S.TextBlue>.
            </S.Textplus> */}

            <Link to={"agendamento"}>
              <Button data-variant-degrade className="buttonAgendamento">
                AGENDAR VISTORIA
              </Button>
            </Link>
          </S.SectionServicesContent>
        </S.SectionServices>

        <S.SectionLocation id="localizacao">
          <S.SectionLocationContent>
            <S.Title>
              Localização{}{" "}
              <img src="/assets/imgs/check-icon.svg" alt="icone de checado" />
            </S.Title>

            <S.SectionLocationWrapperCardsMap>
              <S.SectionLocationCardMap>
                <S.WrapperImgMap
                  target="_blank"
                  href="https://www.google.com.br/maps/place/R.+Chapadinha,+4,+S%C3%A3o+Lu%C3%ADs+-+MA,+65072-852/@-2.4922137,-44.2593521,15.21z/data=!4m5!3m4!1s0x7f68d8c6eaaf73d:0x2ff22426484a03bc!8m2!3d-2.4915126!4d-44.2535711?entry=ttu"
                >
                  <img src="/assets/imgs/map1.svg" alt="mapa de sao luiz" />
                </S.WrapperImgMap>

                <S.TitleLocalization>
                  <img src="/assets/imgs/pin-blue-icon.svg" alt="pin do mapa" />
                  <span>
                    Calhau
                    <br /> São Luís / MA
                  </span>
                </S.TitleLocalization>
                <p>
                  Rua Chapadinha N.04 - Quadra 40,
                  <br />
                  <S.TextStrong> CEP 65072-852</S.TextStrong>
                </p>
              </S.SectionLocationCardMap>

              <S.SectionLocationCardMap>
                <S.WrapperImgMap
                  target="_blank"
                  href="https://maps.app.goo.gl/MGjZUfehbJMduUvU8"
                >
                  <img src="/assets/imgs/map2.svg" alt="mapa de sao luiz" />
                </S.WrapperImgMap>
                <S.TitleLocalization>
                  <img src="/assets/imgs/pin-blue-icon.svg" alt="pin do mapa" />
                  <span>
                    Centro
                    <br /> Bacabal / MA
                  </span>
                </S.TitleLocalization>
                <p>
                  Br 316, SN Centro,
                  <br />
                  <S.TextStrong> CEP 65900-724</S.TextStrong>
                </p>
              </S.SectionLocationCardMap>

              <S.SectionLocationCardMap>
                <S.WrapperImgMap
                  target="_blank"
                  href="https://www.google.com.br/maps/place/Av.+Contorno,+4144+-+Potosi,+Balsas+-+MA,+65800-000/@-7.5148335,-46.0400104,17z/data=!4m6!3m5!1s0x92d5ef72b8053479:0x58ce885f51bb8393!8m2!3d-7.5144506!4d-46.0379397!16s%2Fg%2F11c2151wrp?entry=ttu"
                >
                  <img src="/assets/imgs/map3.svg" alt="mapa de sao luiz" />
                </S.WrapperImgMap>
                <S.TitleLocalization>
                  <img src="/assets/imgs/pin-blue-icon.svg" alt="pin do mapa" />
                  <span>
                    Potosí
                    <br /> Balsas / MA
                  </span>
                </S.TitleLocalization>
                <p>
                  Rua Contorno N 4144B
                  <br /> <S.TextStrong>CEP 658000-000</S.TextStrong>
                </p>
              </S.SectionLocationCardMap>

              <S.SectionLocationCardMap>
                <S.WrapperImgMap
                  target="_blank"
                  href="https://www.google.com/maps/place/2%C2%B031'59.3%22S+45%C2%B005'29.1%22W/@-2.5331389,-45.0914167,1105m/data=!3m2!1e3!4b1!4m4!3m3!8m2!3d-2.5331389!4d-45.0914167?entry=ttu&g_ep=EgoyMDI1MDMwNC4wIKXMDSoASAFQAw%3D%3D"
                >
                  <img src="/assets/imgs/map4.svg" alt="mapa de sao luiz" />
                </S.WrapperImgMap>
                <S.TitleLocalization>
                  <img src="/assets/imgs/pin-blue-icon.svg" alt="pin do mapa" />
                  <span>
                    São Castelo
                    <br /> Pinheiro / MA
                  </span>
                </S.TitleLocalization>
                <p>
                  Rua das Cotias, S/N, João Castelo
                  <br /> <S.TextStrong>CEP 65200-000</S.TextStrong>
                </p>
              </S.SectionLocationCardMap>

              <S.SectionLocationCardMap>
                <S.WrapperImgMap
                  target="_blank"
                  href="https://www.google.com.br/maps/place/Av.+Alexandre+Costa,+3480+-+Volta+Redonda,+Caxias+-+MA,+65605-515/@-4.8784905,-43.3442526,18.21z/data=!4m6!3m5!1s0x78e926f2c1ee513:0x7a4fe4ca5df3da7!8m2!3d-4.8783834!4d-43.3436598!16s%2Fg%2F11tsjtc8r3?entry=ttu"
                >
                  <img src="/assets/imgs/map5.svg" alt="mapa de sao luiz" />
                </S.WrapperImgMap>
                <S.TitleLocalization>
                  <img src="/assets/imgs/pin-blue-icon.svg" alt="pin do mapa" />
                  <span>
                    Volta Redonda
                    <br /> Caxias / MA
                  </span>
                </S.TitleLocalization>
                <p>
                  Av. Alexandre Costa, 3480
                  <br /> <S.TextStrong>CEP 65000-300</S.TextStrong>
                </p>
              </S.SectionLocationCardMap>

              <S.SectionLocationCardMap>
                <S.WrapperImgMap
                  target="_blank"
                  href="https://www.google.com.br/maps/place/R.+Francisco+Bernardino,+1442+-+Cod%C3%B3,+MA,+65400-000/@-4.4692901,-43.8874529,18.21z/data=!4m5!3m4!1s0x78bf118b28a068f:0x3e73c0e7e32d81d2!8m2!3d-4.4693113!4d-43.8868038?entry=ttu"
                >
                  <img src="/assets/imgs/map6.svg" alt="mapa de sao luiz" />
                </S.WrapperImgMap>
                <S.TitleLocalization>
                  <img src="/assets/imgs/pin-blue-icon.svg" alt="pin do mapa" />
                  <span>
                    Santa Lúcia
                    <br /> Codó / MA
                  </span>
                </S.TitleLocalization>
                <p>
                  R. Francisco Bernardino, 1442
                  <br /> <S.TextStrong>CEP 65400-00</S.TextStrong>
                </p>
              </S.SectionLocationCardMap>

              {/* <S.SectionLocationCardMap>
                <S.WrapperImgMap
                  target="_blank"
                  href="https://www.google.com.br/maps/place/7%C2%B021'46.6%22S+46%C2%B037'21.5%22W/@-7.3630442,-46.6229281,19z/data=!4m4!3m3!8m2!3d-7.362956!4d-46.62264?entry=ttu"
                >
                  <img src="/assets/imgs/map7.svg" alt="mapa de sao luiz" />
                </S.WrapperImgMap>
                <S.TitleLocalization>
                  <img src="/assets/imgs/pin-blue-icon.svg" alt="pin do mapa" />
                  <span>
                    Bela Vista
                    <br /> Riachão / MA
                  </span>
                </S.TitleLocalization>
                <p>
                  Rua 31 de Dezembro, N188
                  <br /> <S.TextStrong>CEP 65990-000</S.TextStrong>
                </p>
              </S.SectionLocationCardMap> */}
            </S.SectionLocationWrapperCardsMap>
          </S.SectionLocationContent>
        </S.SectionLocation>

        <S.SectionContact id="contato">
          <S.SectionContactContent>
            <h1>
              Contatos{" "}
              <img
                src="/assets/imgs/check-dark-blue.svg"
                alt="icone de checado"
              />
            </h1>

            <div>
              <S.TitleLocalization>
                <span>Caxias / MA</span>
              </S.TitleLocalization>
              <S.TitleLocalization>
                <span>São Luis / MA</span>
              </S.TitleLocalization>

              <p>(98) 98562-0425</p>
            </div>

            <div>
              <S.TitleLocalization>
                <span>Codó / MA</span>
              </S.TitleLocalization>
              <S.TitleLocalization>
                <span>Bacabal / MA</span>
              </S.TitleLocalization>

              <p>(99) 98420-5510</p>
            </div>

            <div>
              <S.TitleLocalization>
                <span>Pinheiro /MA</span>
              </S.TitleLocalization>
              <S.TitleLocalization>
                <span>Riachão / MA</span>
              </S.TitleLocalization>
              <S.TitleLocalization>
                <span>Balsas / MA</span>
              </S.TitleLocalization>
              <p>(99) 98535-5900</p>
            </div>
          </S.SectionContactContent>
        </S.SectionContact>
      </S.Container>
    </LayoutTemplate>
  );
};
